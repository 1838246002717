import React, { useEffect } from 'react'

import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag, FieldProps, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import ListItem from '@supplyhound/components/common/ListItem'
import PillSelectField from '@supplyhound/forms/fields/PillSelect'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import Spacer from '@supplyhound/components/common/Spacer'
import PlumbingConfig, { PlumbingFormValues, formatValues } from '@supplyhound/forms/shortcuts/PlumbingConfig'

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`
const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
`

const FieldContainer = styled.div`
  position: relative;
`

interface PlumbingFormProps {
  onSubmit: (values: PlumbingFormValues) => Promise<void>
  setHeaderLabel: (label: string) => void
  setSubmitForm: Function
  setOnModelBack: Function
  dismiss: Function
}

const TypePage: React.FC<{ nextPage: () => void; fieldName: string }> = ({ nextPage, fieldName }) => {
  let component
  switch (fieldName) {
    case 'plumbingCategory':
      component = PlumbingCategoryInput
      break
    case 'material':
      component = PlumbingMaterialInput
      break
    case 'applicationType':
      component = PlumbingFittingApplicationInput
      break
    default:
      component = <div />
  }
  return (
    <div>
      <Field name={fieldName} component={component} nextPage={nextPage} />
    </div>
  )
}

const PlumbingCategoryInput: React.FC<FieldProps & { nextPage: () => void }> = ({ field, form, nextPage }) => {
  return (
    <>
      <ErrorLabel fieldName={'plumbingCategory'} />
      <div>
        {Object.keys(PlumbingConfig).map(category => {
          return (
            <StyledListItem
              key={category}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, category)
                nextPage()
              }}
            >
              <TypeContainer>
                {category}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}

const PlumbingMaterialInput: React.FC<FieldProps & { nextPage: () => void }> = ({ field, form, nextPage }) => {
  const category = form.values.plumbingCategory
  // @ts-ignore
  const materials = PlumbingConfig[category]
  return (
    <>
      <ErrorLabel fieldName={'material'} />
      <div>
        {Object.keys(materials).map(category => {
          return (
            <StyledListItem
              key={category}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, category)
                nextPage()
              }}
            >
              <TypeContainer>
                {category}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}

const PlumbingFittingApplicationInput: React.FC<FieldProps & { nextPage: () => void }> = ({
  field,
  form,
  nextPage,
}) => {
  const category = form.values.plumbingCategory
  const material = form.values.material
  // @ts-ignore
  const applicationTypes = PlumbingConfig[category][material]
  return (
    <>
      <ErrorLabel fieldName={'material'} />
      <div>
        {Object.keys(applicationTypes).map(category => {
          return (
            <StyledListItem
              key={category}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, category)
                nextPage()
              }}
            >
              <TypeContainer>
                {category}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}

const PipeTubingOptionsPage: React.FC<{
  submitForm: () => void
  values: PlumbingFormValues
  setHeaderLabel: (label: string) => void
  setFieldValue: FormikHelpers<PlumbingFormValues>['setFieldValue']
  setSubmitForm: Function
}> = ({ submitForm, values, setHeaderLabel, setFieldValue, setSubmitForm }) => {
  const options = PlumbingConfig['Pipe & Tubing'][values.material]

  useEffect(() => {
    setHeaderLabel(formatValues(values))
  })

  useEffect(() => {
    setSubmitForm({ fn: submitForm })
  }, [submitForm])

  useEffect(() => {
    if (options.specification.length === 1) {
      setFieldValue('specification', options.specification[0])
    } else if (options.specification.length === 0) {
      setFieldValue('specification', ' ')
    }
    if (options.applicationType.length === 1) {
      setFieldValue('applicationType', options.applicationType[0])
    } else if (options.applicationType.length === 0) {
      setFieldValue('applicationType', ' ')
    }
    if (options.endType.length === 1) {
      setFieldValue('endType', options.endType[0])
    } else if (options.endType.length === 0) {
      setFieldValue('endType', ' ')
    }
    if (options.lengthAngle.length === 1) {
      setFieldValue('lengthAngle', options.lengthAngle[0])
    } else if (options.lengthAngle.length === 0) {
      setFieldValue('lengthAngle', ' ')
    }
    if (options.insideDiameter.length === 1) {
      setFieldValue('insideDiameter', options.insideDiameter[0])
    } else if (options.insideDiameter.length === 0) {
      setFieldValue('insideDiameter', ' ')
    }
  }, [])

  return (
    <>
      {options.specification.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Specification"
            options={options.specification.map(option => ({ label: option, value: option }))}
            name="specification"
          />
        </FieldContainer>
      )}
      {options.applicationType.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Application"
            options={options.applicationType.map(option => ({ label: option, value: option }))}
            name="applicationType"
          />
        </FieldContainer>
      )}
      {options.endType.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="End Type"
            options={options.endType.map(option => ({ label: option, value: option }))}
            name="endType"
          />
        </FieldContainer>
      )}
      {options.lengthAngle.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Length"
            options={options.lengthAngle.map(option => ({ label: option, value: option }))}
            name="lengthAngle"
          />
        </FieldContainer>
      )}
      {options.insideDiameter.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Inside diameter"
            options={options.insideDiameter.map(option => ({ label: option, value: option }))}
            name="insideDiameter"
          />
        </FieldContainer>
      )}

      <Spacer height={20} />
      <Textarea readOnly value={formatValues(values)} />
      <SubmitButton onClick={() => submitForm()}>Add item</SubmitButton>
    </>
  )
}

const PipeFittingOptionsPage: React.FC<{
  submitForm: () => void
  values: PlumbingFormValues
  setHeaderLabel: (label: string) => void
  setFieldValue: FormikHelpers<PlumbingFormValues>['setFieldValue']
  setSubmitForm: Function
}> = ({ submitForm, values, setHeaderLabel, setFieldValue, setSubmitForm }) => {
  const options = PlumbingConfig['Pipe Fittings'][values.material][values.applicationType]
  setHeaderLabel(formatValues(values))
  useEffect(() => {
    setSubmitForm({ fn: submitForm })
  }, [submitForm])

  useEffect(() => {
    if (options.specification.length === 1) {
      setFieldValue('specification', options.specification[0])
    } else if (options.specification?.length === 0) {
      setFieldValue('specification', ' ')
    }
    if (options.endType.length === 1) {
      setFieldValue('endType', options.endType[0])
    } else if (options.endType.length === 0) {
      setFieldValue('endType', ' ')
    }
    if (options.lengthAngle.length === 1) {
      setFieldValue('lengthAngle', options.lengthAngle[0])
    } else if (options.lengthAngle.length === 0) {
      setFieldValue('lengthAngle', ' ')
    }
    if (options.insideDiameter.length === 1) {
      setFieldValue('insideDiameter', options.insideDiameter[0])
    } else if (options.insideDiameter.length === 0) {
      setFieldValue('insideDiameter', ' ')
    }
  }, [])

  return (
    <>
      {options.specification.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Specification"
            options={options.specification.map(option => ({ label: option, value: option }))}
            name="specification"
          />
        </FieldContainer>
      )}
      {options.endType.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="End Type"
            options={options.endType.map(option => ({ label: option, value: option }))}
            name="endType"
          />
        </FieldContainer>
      )}
      {options.lengthAngle.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label={values.applicationType === 'Elbow' ? 'Angle' : 'Elbow'}
            options={options.lengthAngle.map(option => ({ label: option, value: option }))}
            name="lengthAngle"
          />
        </FieldContainer>
      )}
      {options.insideDiameter.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Inside diameter"
            options={options.insideDiameter.map(option => ({ label: option, value: option }))}
            name="insideDiameter"
          />
        </FieldContainer>
      )}

      <Spacer height={20} />
      <Textarea readOnly value={formatValues(values)} />
      <SubmitButton onClick={() => submitForm()}>Add item</SubmitButton>
    </>
  )
}

const PlumbingForm: React.FC<PlumbingFormProps & FormikProps<PlumbingFormValues>> = ({
  submitForm,
  values,
  setHeaderLabel,
  setSubmitForm,
  setFieldValue,
  setOnModelBack,
  dismiss,
  resetForm,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)

  useEffect(() => {
    setOnModelBack(() => () => {
      const previousPage = currentPage - 1
      if (previousPage < 0) {
        dismiss()
      } else {
        resetForm({
          values: {
            ...initialValues,
            plumbingCategory: previousPage < 1 ? '' : values.plumbingCategory,
            material: previousPage < 2 ? '' : values.material,
            applicationType: previousPage < 3 ? '' : values.applicationType,
          },
        })
        setSubmitForm({ fn: null })
        setHeaderLabel('Plumbing')
        setCurrentPage(previousPage)
      }
    })
  }, [currentPage])

  const startingPage = <TypePage nextPage={() => setCurrentPage(1)} fieldName="plumbingCategory" />
  const materialPage = <TypePage nextPage={() => setCurrentPage(2)} fieldName="material" />
  const applicationPage = <TypePage nextPage={() => setCurrentPage(3)} fieldName="applicationType" />

  const pipeAndTubingPages = [
    startingPage,
    materialPage,
    <PipeTubingOptionsPage
      submitForm={submitForm}
      values={values}
      setHeaderLabel={setHeaderLabel}
      setFieldValue={setFieldValue}
      setSubmitForm={setSubmitForm}
    />,
  ]
  const pipeFittingPages = [
    startingPage,
    materialPage,
    applicationPage,
    <PipeFittingOptionsPage
      submitForm={submitForm}
      values={values}
      setHeaderLabel={setHeaderLabel}
      setFieldValue={setFieldValue}
      setSubmitForm={setSubmitForm}
    />,
  ]
  const plumbingCategory = values.plumbingCategory

  if (currentPage > 0 && plumbingCategory) {
    let page

    switch (plumbingCategory) {
      case 'Pipe & Tubing':
        page = pipeAndTubingPages[currentPage]
        break
      case 'Pipe Fittings':
        page = pipeFittingPages[currentPage]
        break
      default:
        page = <div />
    }
    return <div>{page}</div>
  } else {
    return <div>{startingPage}</div>
  }
}

const initialValues = {
  plumbingCategory: '',
  material: '',
  specification: '',
  applicationType: '',
  endType: '',
  lengthAngle: '',
  insideDiameter: '',
}

export default withFormik<PlumbingFormProps, PlumbingFormValues>({
  displayName: 'PlumbingForm',

  validationSchema: Yup.object().shape({
    plumbingCategory: Yup.string().required('Select a category'),
    material: Yup.string().required('Select a material'),
    specification: Yup.string().required('Select a specification'),
    applicationType: Yup.string().required('Select an application type'),
    endType: Yup.string().required('Select an end type'),
    lengthAngle: Yup.string().required('Select a length/angle'),
    insideDiameter: Yup.string().required('Select an inside diameter'),
  }),
  mapPropsToValues: () => initialValues,

  handleSubmit(values: PlumbingFormValues, { props: { onSubmit } }: FormikBag<PlumbingFormProps, PlumbingFormValues>) {
    onSubmit(values)
  },
})(PlumbingForm)
