import React from 'react'
import { IonText } from '@ionic/react'
import { Field, getIn, FormikProps } from 'formik'
import styled from 'styled-components'

const WrapperDiv = styled.div`
  margin: 10px 0;
  font-size: 0.875rem;
  font-weight: 500;
`

// This component can be used to automatically display the error message linked to the form it's a child of; this should be good enough for most use cases.
// If children (text) is defined, it'll just render the text with the error label styling.
const ErrorLabel = <formValue extends unknown>({ fieldName, children }: { fieldName?: string; children?: string }) => {
  return fieldName ? (
    <Field
      name={fieldName}
      component={({ form }: { form: FormikProps<formValue> }) => {
        const error = getIn(form.errors, fieldName)
        const touched = getIn(form.touched, fieldName)
        return error && touched ? (
          <WrapperDiv>
            <IonText color="danger">{error}</IonText>
          </WrapperDiv>
        ) : null
      }}
    />
  ) : (
    <WrapperDiv>
      <IonText color="danger">{children}</IonText>
    </WrapperDiv>
  )
}

export default ErrorLabel
