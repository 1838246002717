import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FieldProps } from 'formik'
import Pill from '@supplyhound/components/common/Pill'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

const OptionsContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid var(--greyscale-3);
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--greyscale-3);
    border-radius: 8px;
  }
`

interface Props {
  label: ReactNode
  options: Array<{ label: string; value: string; disabled?: boolean }>
  onSelect?: (value: string) => void
  allowDeselect?: boolean
  isSelected?: (option: string, value: string, options: Array<{ label: string; value: string }>) => boolean
}

const PillSelectField: React.FC<FieldProps & Props> = ({
  field: { name, value },
  form: { setFieldValue },
  label,
  options,
  onSelect,
  isSelected,
  allowDeselect = true,
  ...props
}) => {
  return (
    <>
      <h3>{label}</h3>
      <OptionsContainer>
        {options.map(({ label, value: optionValue, disabled }) => {
          const disable = disabled || false

          return (
            <Pill
              key={optionValue}
              label={label}
              isSelected={isSelected ? isSelected(optionValue, value, options) : optionValue === value}
              isDisabled={disable}
              onClick={() => {
                if (optionValue === value && allowDeselect) {
                  setFieldValue(name, '')
                } else {
                  setFieldValue(name, optionValue)
                }
                if (onSelect) onSelect(optionValue)
              }}
            />
          )
        })}
      </OptionsContainer>
      <ErrorLabel fieldName={name} />
    </>
  )
}

export default PillSelectField
