import React, { useEffect } from 'react'

import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag, FieldProps, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import ListItem from '@supplyhound/components/common/ListItem'
import PillSelectField from '@supplyhound/forms/fields/PillSelect'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import Spacer from '@supplyhound/components/common/Spacer'
import FastenerConfig, {
  FastenerFormValues,
  FastenerFields,
  formatValues,
} from '@supplyhound/forms/shortcuts/FastnerConfig'

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`
const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
`

const FieldContainer = styled.div`
  position: relative;
`

interface FastenerFormProps {
  onSubmit: (values: FastenerFormValues) => Promise<void>
  setHeaderLabel: (label: string) => void
  setSubmitForm: Function
  setOnModelBack: Function
  dismiss: Function
}

const TypePage: React.FC<{ nextPage: () => void; fieldName: string }> = ({ nextPage, fieldName }) => {
  let component
  switch (fieldName) {
    case 'category':
      component = CategoryInput
      break
    case 'subcategory':
      component = SubCategoryInput
      break
    default:
      component = <div />
  }
  return (
    <div>
      <Field name={fieldName} component={component} nextPage={nextPage} />
    </div>
  )
}

const CategoryInput: React.FC<FieldProps & { nextPage: () => void }> = ({ field, form, nextPage }) => {
  return (
    <>
      <ErrorLabel fieldName={'category'} />
      <div>
        {Object.keys(FastenerConfig).map(category => {
          return (
            <StyledListItem
              key={category}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, category)
                nextPage()
              }}
            >
              <TypeContainer>
                {category}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}
const SubCategoryInput: React.FC<FieldProps & { nextPage: () => void }> = ({ field, form, nextPage }) => {
  const category = form.values.category
  const subcategories = Object.keys(FastenerConfig[category])
  if (subcategories.length === 1) {
    form.setFieldValue(field.name, subcategories[0])
    nextPage()
  }
  return (
    <>
      <ErrorLabel fieldName={'subcategory'} />
      <div>
        {subcategories.map(subcategory => {
          return (
            <StyledListItem
              key={subcategory}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, subcategory)
                nextPage()
              }}
            >
              <TypeContainer>
                {subcategory}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}

const FasterOptionsPage: React.FC<{
  submitForm: () => void
  values: FastenerFormValues
  setHeaderLabel: (label: string) => void
  setFieldValue: FormikHelpers<FastenerFormValues>['setFieldValue']
  setSubmitForm: Function
}> = ({ submitForm, values, setHeaderLabel, setFieldValue, setSubmitForm }) => {
  const options = FastenerConfig[values.category][values.subcategory]
  const fields = Object.keys(FastenerFields)

  useEffect(() => {
    setHeaderLabel(formatValues(values)!)
  })

  useEffect(() => {
    setSubmitForm({ fn: submitForm })
  }, [submitForm])

  useEffect(() => {
    fields.forEach(field => {
      if (options[field].length === 1) {
        setFieldValue(field, options[field][0])
      } else if (options[field].length === 0) {
        setFieldValue(field, ' ')
      }
    })
  }, [])
  return (
    <>
      {fields.map(field => {
        return (
          <div key={field}>
            {options[field].length > 0 && (
              <FieldContainer>
                <Field
                  component={PillSelectField}
                  label={FastenerFields[field]}
                  options={options[field].map(option => ({ label: option, value: option }))}
                  name={field}
                />
              </FieldContainer>
            )}
          </div>
        )
      })}
      <Spacer height={20} />
      <Textarea readOnly value={formatValues(values)} />
      <SubmitButton onClick={() => submitForm()}>Add item</SubmitButton>
    </>
  )
}

const FastenerForm: React.FC<FastenerFormProps & FormikProps<FastenerFormValues>> = ({
  submitForm,
  values,
  setHeaderLabel,
  setSubmitForm,
  setFieldValue,
  setOnModelBack,
  dismiss,
  resetForm,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)

  useEffect(() => {
    setOnModelBack(() => () => {
      const previousPage = currentPage - 1
      if (previousPage < 0) {
        dismiss()
      } else {
        resetForm({
          values: {
            ...initialValues,
            category: previousPage < 1 ? '' : values.category,
            subcategory: previousPage < 2 ? '' : values.subcategory,
          },
        })
        setSubmitForm({ fn: null })
        setHeaderLabel('Fasteners')
        setCurrentPage(previousPage)
      }
    })
  }, [currentPage])

  const startingPage = <TypePage nextPage={() => setCurrentPage(1)} fieldName="category" />
  const subCategoryPage = <TypePage nextPage={() => setCurrentPage(2)} fieldName="subcategory" />
  const optionsPage = (
    <FasterOptionsPage
      submitForm={submitForm}
      values={values}
      setHeaderLabel={setHeaderLabel}
      setFieldValue={setFieldValue}
      setSubmitForm={setSubmitForm}
    />
  )

  const pages = [startingPage, subCategoryPage, optionsPage]

  return <div>{pages[currentPage]}</div>
}

const initialValues = {
  category: '',
  subcategory: '',
  size: '',
  drive: '',
  materialGrade: '',
  finish: '',
  threadStyle: '',
  type: '',
  outsideDiameter: '',
  outsideDimension: '',
  collation: '',
  shank: '',
  cap: '',
  crown: '',
  leg: '',
  length: '',
  boxSizeLb: '',
  boxSizeCt: '',
}

export default withFormik<FastenerFormProps, FastenerFormValues>({
  displayName: 'FastenerForm',

  validationSchema: Yup.object().shape({
    category: Yup.string().required('Select a category'),
    subcategory: Yup.string().required('Select a subcategory'),
    size: Yup.string().required('Select a size'),
    drive: Yup.string().required('Select a drive'),
    materialGrade: Yup.string().required('Select a material grade'),
    finish: Yup.string().required('Select a finish'),
    threadStyle: Yup.string().required('Select a thread style'),
    type: Yup.string().required('Select a type'),
    outsideDiameter: Yup.string().required('Select an outside diameter'),
    outsideDimension: Yup.string().required('Select an outside dimension'),
    collation: Yup.string().required('Select a collation'),
    shank: Yup.string().required('Select a shank'),
    cap: Yup.string().required('Select a cap'),
    crown: Yup.string().required('Select a crown'),
    leg: Yup.string().required('Select a leg'),
    length: Yup.string().required('Select a length'),
    boxSizeLb: Yup.string().required('Select a box size (lbs)'),
    boxSizeCt: Yup.string().required('Select a box size (count)'),
  }),
  mapPropsToValues: () => initialValues,

  handleSubmit(values: FastenerFormValues, { props: { onSubmit } }: FormikBag<FastenerFormProps, FastenerFormValues>) {
    onSubmit(values)
  },
})(FastenerForm)
