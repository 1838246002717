import React, { useEffect } from 'react'

import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag, FieldProps, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import ListItem from '@supplyhound/components/common/ListItem'
import PillSelectField from '@supplyhound/forms/fields/PillSelect'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import DimensionalConfig, { DimensionalFormValues, formatValues } from '@supplyhound/forms/shortcuts/DimensionalConfig'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import Spacer from '@supplyhound/components/common/Spacer'

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`
const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
`

const FieldContainer = styled.div`
  position: relative;
`

interface DimensionalFormProps {
  onSubmit: (values: DimensionalFormValues) => Promise<void>
  setHeaderLabel: (label: string) => void
  setSubmitForm: Function
  setOnModelBack: Function
  dismiss: Function
}

const DimensionalTypeInput: React.FC<FieldProps & { nextPage: () => void }> = ({ field, form, nextPage }) => {
  return (
    <>
      <ErrorLabel fieldName={'type'} />
      <div>
        {Object.keys(DimensionalConfig).map(wood => {
          return (
            <StyledListItem
              key={wood}
              lines="none"
              onClick={() => {
                form.setFieldValue(field.name, wood)
                nextPage()
              }}
            >
              <TypeContainer>
                {wood}
                <Icon icon={chevronForwardOutline} />
              </TypeContainer>
            </StyledListItem>
          )
        })}
      </div>
    </>
  )
}

const TypePage: React.FC<{ nextPage: () => void }> = ({ nextPage }) => {
  return (
    <div>
      <Field name="type" component={DimensionalTypeInput} nextPage={nextPage} />
    </div>
  )
}

const OptionsPage: React.FC<{
  submitForm: () => void
  values: DimensionalFormValues
  setHeaderLabel: (label: string) => void
  setFieldValue: FormikHelpers<DimensionalFormValues>['setFieldValue']
  setSubmitForm: Function
}> = ({ submitForm, values, setHeaderLabel, setFieldValue, setSubmitForm }) => {
  const options = DimensionalConfig[values.type]

  useEffect(() => {
    setHeaderLabel(formatValues(values))
  })

  useEffect(() => {
    setSubmitForm({ fn: submitForm })
  }, [submitForm])

  useEffect(() => {
    if (options.size.length === 1) {
      setFieldValue('size', options.size[0])
    } else if (options.size.length === 0) {
      setFieldValue('size', ' ')
    }
    if (options.length.length === 1) {
      setFieldValue('length', options.length[0])
    } else if (options.length.length === 0) {
      setFieldValue('length', ' ')
    }
    if (options.grade.length === 1) {
      setFieldValue('grade', options.grade[0])
    } else if (options.grade.length === 0) {
      setFieldValue('grade', ' ')
    }
  }, [])

  return (
    <>
      {options.size.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Size"
            options={options.size.map(option => ({ label: option, value: option }))}
            name="size"
          />
        </FieldContainer>
      )}
      {options.length.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Length"
            options={options.length.map(option => ({ label: option, value: option }))}
            name="length"
          />
        </FieldContainer>
      )}
      {options.grade.length > 0 && (
        <FieldContainer>
          <Field
            component={PillSelectField}
            label="Grade"
            options={options.grade.map(option => ({ label: option, value: option }))}
            name="grade"
          />
        </FieldContainer>
      )}

      <Spacer height={20} />
      <Textarea readOnly value={formatValues(values)} />
      <SubmitButton onClick={() => submitForm()}>Add item</SubmitButton>
    </>
  )
}

const DimensionalForm: React.FC<DimensionalFormProps & FormikProps<DimensionalFormValues>> = ({
  submitForm,
  values,
  setHeaderLabel,
  setSubmitForm,
  setFieldValue,
  setOnModelBack,
  dismiss,
  resetForm,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)

  useEffect(() => {
    setOnModelBack(() => () => {
      const previousPage = currentPage - 1
      if (previousPage < 0) {
        dismiss()
      } else {
        resetForm()
        setSubmitForm({ fn: null })
        setHeaderLabel('Dimensional')
        setCurrentPage(previousPage)
      }
    })
  }, [currentPage])

  const pages = [
    <TypePage nextPage={() => setCurrentPage(1)} />,
    <OptionsPage
      submitForm={submitForm}
      values={values}
      setHeaderLabel={setHeaderLabel}
      setFieldValue={setFieldValue}
      setSubmitForm={setSubmitForm}
    />,
  ]
  return <div>{pages[currentPage]}</div>
}

export default withFormik<DimensionalFormProps, DimensionalFormValues>({
  displayName: 'DimensionalForm',

  validationSchema: Yup.object().shape({
    type: Yup.string().required('Select a type'),
    size: Yup.string().required('Select a size'),
    length: Yup.string().required('Select a length'),
    grade: Yup.string().optional(),
  }),
  mapPropsToValues: () => ({
    type: '',
    size: '',
    length: '',
    grade: '',
  }),

  handleSubmit(
    values: DimensionalFormValues,
    { props: { onSubmit } }: FormikBag<DimensionalFormProps, DimensionalFormValues>
  ) {
    onSubmit(values)
  },
})(DimensionalForm)
