import React, { ReactElement, ReactNode } from 'react'
import { IonItemGroup } from '@ionic/react'
import { FieldProps, getIn } from 'formik'
import clsx from 'clsx'
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons'
import styled from 'styled-components'
import ExpandableList from '@supplyhound/components/common/ExpandableList'
import Item from '@supplyhound/components/common/Item'
import useDynamicHeight, { CollapsibleStates, HeightState, HeightStates } from '@supplyhound/hooks/useDynamicHeight'
import useTargetHeight from '@supplyhound/hooks/useTargetHeight'
import FieldHeader from './FieldHeader'
import { withInputBorder } from './InputBorder'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

export type LabeledValue = {
  label: string
  value: any
  disabled?: boolean
}

type Props<ChoiceType extends LabeledValue> = {
  label: string
  choices: ChoiceType[]
  className: string
  controlDisplay?: string
  placeholder?: string
  onSelect?: (value: ChoiceType['value']) => void
  extraContent?: ReactElement
  renderRow?: (row: ChoiceType, index: number, handleChoiceClick: (choice: ChoiceType) => void) => ReactNode
}

const ExpandableListWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`

const StyledExpandableList = withInputBorder(ExpandableList)

const ControlItem = styled(Item)<{ heightState: HeightState }>`
  --border-color: ${({ heightState }) =>
    heightState === HeightStates.Collapsed ? 'transparent' : 'var(--greyscale-3)'};

  ::part(native) {
    --padding-top: 0;
    --padding-bottom: 0;
    height: ${({ theme }) => theme.fieldHeight};
    background: none;
  }
`

const ChoiceItem = styled(Item)`
  ::part(native) {
    --padding-top: 0;
    --padding-bottom: 0;
    background: none;
    height: 50px;
    border-color: var(--ion-border-color);
  }
`

const StyledPlaceholder = styled.span`
  color: var(--ion-color-medium-shade);
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const DropdownField = <ChoiceType extends LabeledValue>({
  field: { name, value },
  choices,
  form,
  label,
  className,
  controlDisplay,
  placeholder,
  onSelect,
  extraContent,
  renderRow,
}: FieldProps & Props<ChoiceType>) => {
  const { hostRef, toggle, collapse, heightState } = useDynamicHeight<HTMLDivElement>({
    onStateChange: state => {
      if (state === 'Collapsed') {
        form.setFieldTouched(name, true)
      }
    },
  })
  const { targetRef: targetHeightSourceRef, height } = useTargetHeight<HTMLDivElement>()

  const handleChoiceClick = (choice: ChoiceType) => {
    form.setFieldValue(name, choice.value)
    onSelect && onSelect(choice.value)
    collapse()
  }

  const controlIcon = heightState in CollapsibleStates ? chevronDownOutline : chevronForwardOutline

  const error = getIn(form.errors, name)
  const touched = getIn(form.touched, name)

  return (
    <IonItemGroup className={className}>
      <FieldHeader label={label} />
      <FlexContainer>
        <ExpandableListWrapper style={{ minHeight: height }} ref={hostRef}>
          <StyledExpandableList
            state={heightState}
            onClick={toggle}
            scroll
            control={
              <div ref={targetHeightSourceRef}>
                <ControlItem detail heightState={heightState} detailIcon={controlIcon} lines="full">
                  {controlDisplay ??
                    (choices.find(choice => choice.value === value)?.label || (
                      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
                    ))}
                </ControlItem>
              </div>
            }
            className={clsx({ 'ion-invalid': error && touched })}
          >
            {choices.map((choice, index) => {
              return renderRow ? (
                renderRow(choice, index, handleChoiceClick)
              ) : (
                <ChoiceItem
                  key={index}
                  lines={index === choices.length - 1 ? 'none' : 'full'}
                  button
                  detail={false}
                  disabled={choice.disabled}
                  onClick={() => handleChoiceClick(choice)}
                >
                  {choice.label}
                </ChoiceItem>
              )
            })}
          </StyledExpandableList>
        </ExpandableListWrapper>
        {extraContent}
      </FlexContainer>
      <ErrorLabel fieldName={name} />
    </IonItemGroup>
  )
}

export default DropdownField
